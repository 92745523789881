import { DateTool } from "@js/date-tool.js";
import { findKey } from "@js/object-tool.js";

class ReportModel {
    // constructor
    constructor(model) {
        this.model = model
        this.reportID = this.model.report.id
        this.patientNum = this.model.user.number;
        this.abstract = this.model.report.epitomize;
        this.suggestion = this.model.report.advise;
        this.basicInfoArray = this._initBasicInfoArray() //基本信息
        this.settingInfoArray = this._initSettingInfoArray() //设置信息
        this.deviceModel = this._initDeviceModel()
        this.deviceSN = this._initDeviceSN()
        this.deviceStatisticsDuration = this._initDeviceStatisticsDuration()
        this.initialStartTime = this.model.report.startTime
        this.initialEndTime = this.model.report.endTime
        this.eventStatisticsArray = this._initEventStatisticsArray() //事件统计
        this.cureDataArray = this._initCureDataArray() //治疗数据

    }

    // init
    _initBasicInfoArray() {
        const name = placeholderText(this.model.user?.name);
        const height = placeholderText(this.model.user?.height, "cm");
        const gender = placeholderText(findKey(USER_GENDER, this.model.user?.sex));
        const weight = placeholderText(this.model.user?.weight, "kg");
        const age = placeholderText(this.model.user?.age);
        const BMI = placeholderText(this._computeBMI(height, weight));
        return [
            { title: "姓名", info: name },
            { title: "身高", info: height },
            { title: "性别", info: gender },
            { title: "体重", info: weight },
            { title: "年龄", info: age },
            { title: "BMI", info: BMI },
        ];
    }

    _initSettingInfoArray() {
        const seprateInfoArray = this._composeSeparateSettingInfoArray()
        const commonInfoArray = this._composeCommonSettingInfoArray()
        return seprateInfoArray.concat(commonInfoArray)
    }

    _initDeviceModel() {
        return placeholderText(this.model.device.model)
    }

    _initDeviceSN() {
        return placeholderText(this.model.device.sn)
    }

    _initDeviceStatisticsDuration() {
        const startTime = DateTool.milliTimestampToStr(this.model.report.startTime)
        const endTime = DateTool.milliTimestampToStr(this.model.report.endTime)
        return `${placeholderText(startTime)} ～ ${placeholderText(endTime)}`
    }

    _initEventStatisticsArray() {
        const AHI = placeholderText(this.model.report.ahi);
        const CSA = placeholderText(this.model.report.csa);
        const AI = placeholderText(this.model.report.ai);
        const CSR = placeholderText(this.model.report.csr);
        const HI = placeholderText(this.model.report.hi);
        const PB = placeholderText(this.model.report.pb);
        return [
            { title: "AHI", info: AHI },
            { title: "CSA", info: CSA },
            { title: "AI", info: AI },
            { title: "CSR", info: CSR },
            { title: "HI", info: HI },
            { title: "PB", info: PB },
        ];
    }

    _initCureDataArray() {
        // 90%压力
        const pressure90 = placeholderText(this.model.report.tpIn, "cmH2O");
        // 总时长
        const lastingTime = placeholderText(
            DateTool.formatSeconds(this.model.report.longTime)
        );
        //平均漏气
        const averageLeak = placeholderText(this.model.report.leakage, "L/min");
        //总漏气
        const allLeak = placeholderText(this.model.report.totalLeakage, "L");

        //心率和血氧返回值为字符串，要求为0时显示“-”，因此需要用parseInt转化
        //平均血氧
        const averageBloodOxygen = placeholderText(parseInt(this.model.report.spoAvg), "%");
        //最低血氧
        const minBloodOxygen = placeholderText(parseInt(this.model.report.spoMin), "%");
        //<90%血氧
        const bloodOxygen90 = placeholderText(
            parseInt(this.model.report.spoNinetyPercent),
            "%"
        );
        //平均心率
        const averagePulse = placeholderText(parseInt(this.model.report.bpmMax), "bpm");
        //最大心率
        const maxPulse = placeholderText(parseInt(this.model.report.bpmAvg), "bpm");
        //最小心率
        const minPulse = placeholderText(parseInt(this.model.report.bpmMin), "bpm");

        return [
            {
                title: "90%压力",
                info: pressure90,
            },
            {
                title: "总时长",
                info: lastingTime,
            },
            {
                title: "平均漏气",
                info: averageLeak,
            },
            {
                title: "总漏气",
                info: allLeak,
            },
            {
                title: "平均血氧",
                info: averageBloodOxygen,
            },
            {
                title: "最低血氧",
                info: minBloodOxygen,
            },
            {
                title: "<90%血氧",
                info: bloodOxygen90,
                width: 100,
            },
            {
                title: "最大心率",
                info: maxPulse,
            },
            {
                title: "最小心率",
                info: minPulse,
            },
            {
                title: "平均心率",
                info: averagePulse,
                width: 100,
            },
        ];
    }

    // method
    _computeBMI(height, weight) {
        const kgWeight = parseFloat(weight);
        const miterHeight = parseFloat(height) / 100;
        if (kgWeight && miterHeight) {
            return (kgWeight / (miterHeight * miterHeight)).toFixed(1);
        } else {
            return "";
        }
    }

    _composeSeparateSettingInfoArray() {
        //通用参数
        let cureMode = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "治疗模式":
                    cureMode = placeholderText(item.paramLabel)
                    break
                default:
                    break;
            }
        }
        const modeInfoArray = [{
            title: "工作模式",
            info: cureMode,
        }]
        let separateSettingInfoArray = []
        switch (cureMode) {
            case "CPAP":
                separateSettingInfoArray = this._composeCPAPSettingInfoArray()
                break;
            case "APAP":
                separateSettingInfoArray = this._composeAPAPSettingInfoArray()
                break;
            case "BPAP-S":
                separateSettingInfoArray = this._composeBPAPSSettingInfoArray()
                break;
            case "AutoBPAP-S":
                separateSettingInfoArray = this._composeAutoBPAPSSettingInfoArray()
                break;
            case "BPAP-T":
                separateSettingInfoArray = this._composeBPAPTSettingInfoArray()
                break;
            case "BPAP-ST":
                separateSettingInfoArray = this._composeBPAPSTSettingInfoArray()
                break;
            default:
                break;
        }
        return modeInfoArray.concat(separateSettingInfoArray)
    }

    _composeCPAPSettingInfoArray() {
        let startPressure = ""
        let treasurePressure = ""
        let breathReleasePressure = ""
        let releasePressureLevel = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "起始压力":
                    startPressure = placeholderText(item.paramLabel);
                    break;
                case "治疗压力":
                    treasurePressure = placeholderText(item.paramLabel);
                    break;
                case "呼气释压":
                    breathReleasePressure = placeholderText(item.paramLabel);
                    break;
                case "释压水平":
                    releasePressureLevel = placeholderText(item.paramLabel);
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "起始压力",
                info: startPressure,
            },
            {
                title: "治疗压力",
                info: treasurePressure,
                width: 100,
            },
            {
                title: "呼气释压",
                info: breathReleasePressure,
            },
            {
                title: "释压水平",
                info: releasePressureLevel,
            }]
    }


    _composeAPAPSettingInfoArray() {
        let startPressure = ""
        let minPressure = ""
        let maxPressure = ""
        let breathReleasePressure = ""
        let releasePressureLevel = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "起始压力":
                    startPressure = placeholderText(item.paramLabel);
                    break;
                case "最小压力":
                    minPressure = placeholderText(item.paramLabel);
                    break;
                case "最大压力":
                    maxPressure = placeholderText(item.paramLabel);
                    break;
                case "呼气释压":
                    breathReleasePressure = placeholderText(item.paramLabel);
                    break;
                case "释压水平":
                    releasePressureLevel = placeholderText(item.paramLabel);
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "起始压力",
                info: startPressure,
            },
            {
                title: "最大压力",
                info: maxPressure,
            },
            {
                title: "最小压力",
                info: minPressure,
            },
            {
                title: "呼气释压",
                info: breathReleasePressure,
            },
            {
                title: "释压水平",
                info: releasePressureLevel,
            }]
    }

    _composeBPAPSSettingInfoArray() {
        let startPressure = ""
        let breathInPressure = ""
        let breathOutPressure = ""
        let upPressureSpeed = ""
        let downPressureSpeed = ""
        let breathInSensitivity = ""
        let breathOutSensitivity = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "起始压力":
                    startPressure = placeholderText(item.paramLabel);
                    break;
                case "吸气压力":
                    breathInPressure = placeholderText(item.paramLabel);
                    break;
                case "呼气压力":
                    breathOutPressure = placeholderText(item.paramLabel);
                    break;
                case "升压速度":
                    upPressureSpeed = placeholderText(item.paramLabel)
                    break;
                case "降压速度":
                    downPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "吸气灵敏度":
                    breathInSensitivity = placeholderText(item.paramLabel);
                    break;
                case "呼气灵敏度":
                    breathOutSensitivity = placeholderText(item.paramLabel);
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "起始压力",
                info: startPressure,
            },
            {
                title: "吸气压力",
                info: breathInPressure,
            },
            {
                title: "呼气压力",
                info: breathOutPressure,
            },
            {
                title: "升压速度",
                info: upPressureSpeed,
            },
            {
                title: "降压速度",
                info: downPressureSpeed,
            }, {
                title: "吸气灵敏度",
                info: breathInSensitivity,
            },
            {
                title: "呼气灵敏度",
                info: breathOutSensitivity,
            }]
    }

    _composeAutoBPAPSSettingInfoArray() {
        let startPressure = ""
        let maxSupportPressure = ""
        let minSupportPressure = ""
        let maxBreathInPressure = ""
        let minBreathOutPressure = ""
        let upPressureSpeed = ""
        let downPressureSpeed = ""
        let breathInSensitivity = ""
        let breathOutSensitivity = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "起始压力":
                    startPressure = placeholderText(item.paramLabel);
                    break;
                case "最大压力支持":
                    maxSupportPressure = placeholderText(item.paramLabel);
                    break;
                case "最小压力支持":
                    minSupportPressure = placeholderText(item.paramLabel);
                    break;
                case "最大吸气压力":
                    maxBreathInPressure = placeholderText(item.paramLabel);
                    break;
                case "最小呼气压力":
                    minBreathOutPressure = placeholderText(item.paramLabel);
                    break;
                case "升压速度":
                    upPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "降压速度":
                    downPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "吸气灵敏度":
                    breathInSensitivity = placeholderText(item.paramLabel);
                    break;
                case "呼气灵敏度":
                    breathOutSensitivity = placeholderText(item.paramLabel);
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "起始压力",
                info: startPressure,
            },
            {
                title: "最大压力支持",
                info: maxSupportPressure,
            },
            {
                title: "最小压力支持",
                info: minSupportPressure,
            },
            {
                title: "最大吸气压力",
                info: maxBreathInPressure,
            },
            {
                title: "最小呼气压力",
                info: minBreathOutPressure,
            },
            {
                title: "升压速度",
                info: upPressureSpeed,
            },
            {
                title: "降压速度",
                info: downPressureSpeed,
            },
            {
                title: "吸气灵敏度",
                info: breathInSensitivity,
            },
            {
                title: "呼气灵敏度",
                info: breathOutSensitivity,
            }]
    }

    _composeBPAPTSettingInfoArray() {
        let startPressure = ""
        let breathInPressure = ""
        let breathOutPressure = ""
        let upPressureSpeed = ""
        let downPressureSpeed = ""
        let breathFrequency = ""
        let breathRatio = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "起始压力":
                    startPressure = placeholderText(item.paramLabel);
                    break;
                case "吸气压力":
                    breathInPressure = placeholderText(item.paramLabel);
                    break;
                case "呼气压力":
                    breathOutPressure = placeholderText(item.paramLabel);
                    break;
                case "升压速度":
                    upPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "降压速度":
                    downPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "呼吸频率":
                    breathFrequency = placeholderText(item.paramLabel);
                    break;
                case "吸呼比":
                    breathRatio = placeholderText(item.paramLabel);
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "起始压力",
                info: startPressure,
            },
            {
                title: "吸气压力",
                info: breathInPressure,
            },
            {
                title: "呼气压力",
                info: breathOutPressure,
            },
            {
                title: "升压速度",
                info: upPressureSpeed,
            },
            {
                title: "降压速度",
                info: downPressureSpeed,
            },
            {
                title: "呼吸频率",
                info: breathFrequency,
            },
            {
                title: "吸呼比",
                info: breathRatio,
            }]
    }

    _composeBPAPSTSettingInfoArray() {
        let startPressure = ""
        let breathInPressure = ""
        let breathOutPressure = ""
        let upPressureSpeed = ""
        let downPressureSpeed = ""
        let breathFrequency = ""
        let breathRatio = ""
        let minBreathInTime = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "起始压力":
                    startPressure = placeholderText(item.paramLabel);
                    break;
                case "吸气压力":
                    breathInPressure = placeholderText(item.paramLabel);
                    break;
                case "呼气压力":
                    breathOutPressure = placeholderText(item.paramLabel);
                    break;
                case "升压速度":
                    upPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "降压速度":
                    downPressureSpeed = placeholderText(item.paramLabel);
                    break;
                case "呼吸频率":
                    breathFrequency = placeholderText(item.paramLabel);
                    break;
                case "吸呼比":
                    breathRatio = placeholderText(item.paramLabel);
                    break;
                case "最小吸气时间":
                    minBreathInTime = placeholderText(item.paramLabel)
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "起始压力",
                info: startPressure,
            },
            {
                title: "吸气压力",
                info: breathInPressure,
            },
            {
                title: "呼气压力",
                info: breathOutPressure,
            },
            {
                title: "升压速度",
                info: upPressureSpeed,
            },
            {
                title: "降压速度",
                info: downPressureSpeed,
            },
            {
                title: "呼吸频率",
                info: breathFrequency,
            },
            {
                title: "吸呼比",
                info: breathRatio,
            }, {
                title: "最小吸气时间",
                info: minBreathInTime,
                width: 100
            }]
    }

    _composeCommonSettingInfoArray() {
        let delayPressureTime = ""
        let moistGrade = ""
        let smartStart = ""
        let smartStop = ""
        let leakNoteTime = ""
        let maskType = ""
        let tubeType = ""
        let tubeTemperature = ""
        let temperatureUnit = ""
        for (const item of this.model.parameter) {
            switch (item.paramName) {
                case "延迟时间":
                    delayPressureTime = placeholderText(item.paramLabel)
                    break;
                case "湿化等级":
                    moistGrade = placeholderText(item.paramLabel)
                    break;
                case "智能启动":
                    smartStart = placeholderText(item.paramLabel);
                    break;
                case "智能停止":
                    smartStop = placeholderText(item.paramLabel);
                    break;
                case "漏气提示时间":
                    leakNoteTime = placeholderText(item.paramLabel)
                    break;
                case "面罩类型":
                    maskType = placeholderText(item.paramLabel)
                    break;
                case "管道类型":
                    tubeType = placeholderText(item.paramLabel)
                    break;
                case "加温管温度":
                    tubeTemperature = placeholderText(item.paramLabel)
                    break;
                case "温度单位":
                    temperatureUnit = item.paramLabel == "摄氏度" ? "°C" : "°F"
                    break;
                default:
                    break;
            }
        }
        return [
            {
                title: "延迟升压",
                info: delayPressureTime,
            },
            {
                title: "湿化等级",
                info: moistGrade,
            },
            {
                title: "智能启动",
                info: smartStart,
            },
            {
                title: "智能停止",
                info: smartStop,
            },
            {
                title: "漏气提示时间",
                info: leakNoteTime,
            },
            {
                title: "面罩类型",
                info: maskType,
            },
            {
                title: "管道类型",
                info: tubeType,
            },
            {
                title: "加温管温度",
                info: `${tubeTemperature}${temperatureUnit}`,
            }]
    }

}

export { ReportModel };
