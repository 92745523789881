<template>
  <div v-if="reportModel" class="single-report">
    <div class="single-report-container">
      <!-- 第一页 -->
      <print-page :printHeaderInfo="printHeaderInfo" :currentPage="1" :totalPage="3">
        <div>
          <div class="single-report-container-top">
            <div class="single-report-container-top-top">
              <p class="single-report-container-top-top-title">使用记录</p>
              <barcode v-if="reportModel.patientNum" :patientNum="reportModel.patientNum" />
            </div>
            <div class="single-report-container-top-bottom">
              <div class="single-report-container-top-bottom-left">
                <title-info-label title="设备序列号" :info="reportModel.deviceSN" />
                <title-info-label title="设备型号" :info="reportModel.deviceModel" />
              </div>
              <title-info-label title="统计时间" :info="reportModel.deviceStatisticsDuration" />
            </div>
          </div>
          <report-info-block title="基本信息" :dataArray="reportModel.basicInfoArray"
            :widthPercent="100"></report-info-block>
          <report-info-block title="事件统计" :dataArray="reportModel.eventStatisticsArray"
            :widthPercent="100"></report-info-block>
        </div>
      </print-page>
      <!-- 第二页 -->
      <print-page :printHeaderInfo="printHeaderInfo" :currentPage="2" :totalPage="3">
        <div>
          <report-info-block title="治疗数据" :dataArray="reportModel.cureDataArray"
            :widthPercent="100"></report-info-block>
          <report-info-block title="设置信息" :dataArray="reportModel.settingInfoArray"
            :widthPercent="100"></report-info-block>
        </div>
      </print-page>
      <!-- 第三页 -->
      <print-page :printHeaderInfo="printHeaderInfo" :currentPage="3" :totalPage="3">
        <div>
          <input-box title="概述" :canSave="true" :inputContent="abstract" @onSaveClick="onAbstractSaveClick"></input-box>
          <input-box title="建议" :canSave="true" :inputContent="suggestion"
            @onSaveClick="onSuggestionSaveClick"></input-box>
        </div>
      </print-page>
    </div>
  </div>
</template>

<script>
import TitleInfoLabel from "@c/report/title-info-label.vue";
import ReportInfoBlock from "@c/report/report-info-block.vue";
import Barcode from "@c/report/barcode.vue";
import InputBox from "@c/report/input-box.vue";
import PrintPage from "@c/common/print-page.vue";
import { ReportModel } from "@js/model/report-model.js";
export default {
  components: {
    TitleInfoLabel,
    ReportInfoBlock,
    PrintPage,
    Barcode,
    InputBox,
  },

  props: {
    selectedInfo: Object,
  },

  data() {
    return {
      reportModel: null,
      //getReportDetail时赋初始值，onAbstractSaveClick时赋改变值
      abstract: "",
      //getReportDetail时赋初始值，onSuggestionSaveClick时赋改变值
      suggestion: "",
    };
  },

  computed: {
    //依赖reportModel
    printHeaderInfo() {
      return {
        patientName: this.reportModel?.model.user.name,
        deviceModel: this.reportModel?.deviceModel,
        deviceSN: this.reportModel?.deviceSN,
        statisticsTime: this.reportModel?.deviceStatisticsDuration,
      };
    },
  },

  created() {
    this.reloadData();
  },

  watch: {
    selectedInfo() {
      this.reloadData();
    },
  },

  methods: {
    // action
    onAbstractSaveClick(content) {
      this.abstract = content;
      this.modifyReportAbstract();
    },

    onSuggestionSaveClick(content) {
      this.suggestion = content;
      this.modifyReportSuggestion();
    },

    async reloadData() {
      this.reportModel = await this.getReportDetail();
      this.abstract = this.reportModel?.abstract;
      this.suggestion = this.reportModel?.suggestion;
    },

    async getReportDetail() {
      try {
        const params = {
          id: this.selectedInfo.singleBreathReportId,
        };
        const data = await this.$api.getSingleReportDetail(params);
        return new ReportModel(data);
      } catch (error) {
        this.$emit("onNoReport");
      }
    },

    async modifyReportAbstract() {
      const params = {
        reportId: this.selectedInfo.singleBreathReportId,
        epitomize: this.abstract,
      }
      try {
        await this.$api.modifyReportInfo(params)
        this.$toast.showGreen("概述修改成功");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async modifyReportSuggestion() {
      const params = {
        reportId: this.selectedInfo.singleBreathReportId,
        advise: this.suggestion,
      }
      try {
        await this.$api.modifyReportInfo(params)
        this.$toast.showGreen("建议修改成功");
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .single-report-container {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .no-print {
    display: none !important;
  }
}

.single-report {
  width: 100%;

  &-container {
    width: 900px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 100px;
    padding-bottom: 100px;

    &-top {
      background-color: #f7f7f7;
      box-sizing: border-box;
      padding-top: 33px;
      padding-bottom: 20px;
      width: 100%;
      height: 171px;

      &-top {
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 10px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          color: black;
          font-size: 48px;
          font-weight: bold;
          line-height: 33px;
        }
      }

      &-bottom {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;

        &-left {
          display: flex;

          :first-child {
            margin-right: 27px;
          }
        }
      }
    }
  }
}
</style>