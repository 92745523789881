<template>
  <div class="page-print">
    <div class="page-print-content">
      <print-header :info="printHeaderInfo" />
      <slot />
      <div style="flex: 1"></div>
      <print-footer :firstPage="currentPage" :totalPage="totalPage" />
    </div>
    <div style="page-break-after: always"></div>
  </div>
</template>

<script>
import PrintHeader from "@c/report/print/print-header.vue";
import PrintFooter from "@c/report/print/print-footer.vue";
export default {
  components: {
    PrintHeader,
    PrintFooter,
  },

  props: {
    printHeaderInfo: Object,
    currentPage: Number,
    totalPage: Number,
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .m-header {
    display: block !important;
  }
  .m-footer {
    display: block !important;
  }

  .page-print {

    &-content {
      display: flex;
      flex-direction: column;
      height: 1300px;
    }
  }
}
</style>