<template>
  <div class="input-box">
    <div class="input-box-top">
      <p class="input-box-top-title">{{ title }}</p>
      <button
        v-if="canSave"
        class="input-box-top-save no-print"
        @click="onSaveClick"
      >
        保存
      </button>
    </div>
    <el-input
      type="textarea"
      placeholder="请输入"
      :maxlength="500"
      v-model="content"
    >
    </el-input>
  </div>
</template>

<script>
import { Input } from "element-ui";
export default {
  components: {
    elInput: Input,
  },

  props: {
    title: String,
    canSave: Boolean,
    inputContent: String,
  },

  data() {
    return {
      content: "",
    };
  },

  created() {
    this.content = this.inputContent;
  },

  watch:{
    inputContent(value){
      this.content = value
    }
  },

  methods: {
    onSaveClick() {
      this.$emit("onSaveClick", this.content);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none !important;
  }
}

.input-box {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 20px 20px 10px;
  margin-top: 20px;

  &-top {
    height: 32px;
    display: flex;
    justify-content: space-between;

    &-title {
      color: black;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
    }

    &-save {
      color: white;
      background-color: #1890fb;
      font-size: 14px;
      width: 65px;
      height: 32px;
    }
  }
}
</style>

<style lang="scss">
;

.el-textarea__inner {
  color: black;
  font-size: 14px;
  width: 90%;
  padding: 0;
  border: none;
}
</style>